import React from 'react'
import { Parallax } from 'react-parallax'
import { CFImage, CFView, DefaultScreen, MobileScreen } from 'components'
import { about, galleryTitle } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView column center>
          {/* <CFImage
            src={mobileAbout}
            w="100%"
            pv="5px"
            alt="Dolpan Seoul BBQ About"
          /> */}
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView m="0 auto">
          <CFView
            column
            justifyEnd
            alignCenter
            h="550px"
            w="100%"
            maxWidth="1400px"
            image={`url(${about}) bottom / cover no-repeat`}
            style={{ backgroundAttachment: 'fixed' }}
          >
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
